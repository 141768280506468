<template>
  <div>
    <div v-for="(stateCode, indexMain) in states" :key="indexMain" class="p-2 mx-5 bg-white border rounded-lg shadow-sm my-5">
      <div class="text-xl font-bold">State: {{ stateCode.toUpperCase() }}</div>
      <div :id="stateCode + 'Chart'" class="chart-container"></div>

      <div class="flex flex-row w-full">
        <div class="flex-flex-col mt-10">
          <div class="text-lg font-bold mr-20 mb-3 underline">
            Still Need to Sell
          </div>
          <div v-for="(county, index) in county_data[stateCode].available" :key="index"
            :class="`flex flex-row ${getAddressStatus(county)}`">
            <feather-icon v-if="county.creds" class="text-success" icon="KeyIcon" /><feather-icon v-else
              class="text-danger" icon="LockIcon" />
            <div>{{ county.name }}</div>
          </div>
        </div>
        <div class="flex-flex-col mt-10">
          <div class="text-lg font-bold mr-20 mb-3 underline">Price</div>
          <div v-for="(county, index) in county_data[stateCode].available" :key="index">
            {{ county.price != 0 ? county.price : "?" | currency }}
          </div>
          <div class="text-lg font-bold mr-20 mb-3 underline mt-3">
            {{ total_value | currency }}
          </div>
        </div>
        <div class="flex-flex-col mt-10">
          <div class="text-lg font-bold mr-20 mb-3 underline">Sold</div>
          <div v-for="(county, index) in county_data[stateCode].sold" :key="index">
            {{ county.name }} || {{ county.subs }}
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        ** <feather-icon class="text-success ml-1" icon="KeyIcon" /><feather-icon class="text-danger mr-1"
          icon="LockIcon" />
        Icons indicate if login credentials are available.
      </div>
      <div class="flex flex-row">
        <span class="line-through mr-1"> MARKED OUT </span> indicates there is no
        address information available.
      </div>
    </div>
  </div>
</template>
<!-- <script src="https://www.amcharts.com/lib/4/core.js"></script>
<script src="https://www.amcharts.com/lib/4/maps.js"></script>
<script src="https://www.amcharts.com/lib/4/geodata/region/usa/tnLow.js"></script> -->
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";


//import { BCard } from "bootstrap-vue"

export default {
  components: {},
  data() {
    return {
      crawls: {},
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
      county_data: [],
      total_value: 0,
      stateDataMap: {},
      states: []
    };
  },
  beforeDestroy() {
    for (const chart of Object.values(this.stateDataMap)) {
      if (chart) {
        chart.dispose();
      }
    }
  },

  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");

    this.getSubscriptionData();
    //this.getCrawlerStats();
  },

  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth);
  },

  methods: {
    async loadStateData(stateCodes) {
      // Use Promise.all to wait for all the promises to resolve
      await Promise.all(stateCodes.map(async (stateCode) => {
        try {
          let module = await import(`@amcharts/amcharts4-geodata/region/usa/${stateCode}Low.js`);
          // Wait for the chart to be created before resolving the promise
          await this.$nextTick();
          this.stateDataMap[stateCode] = this.createGraph(module, stateCode);
        } catch (error) {
          console.log(`Failed to load data for ${stateCode}`);
        }
      }));
    },
    getAddressStatus(county) {
      if (!county.addresses) {
        return "line-through";
      } else {
        return "";
      }
    },
    createGraph(stateJs, stateCode) {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          
          var chart = am4core.create(this.$el.querySelector(`#${stateCode}Chart`), am4maps.MapChart);

          console.log(chart.series, "------");
          // Set map definition

          chart.geodata = stateJs.default;
          
          // Set projection
          chart.projection = new am4maps.projections.Miller();

          // Create map polygon series
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
          //
          // // Make map load polygon (like country names) data from GeoJSON
          polygonSeries.useGeodata = true;

          // Configure series
          var polygonTemplate = polygonSeries.mapPolygons.template;
          polygonTemplate.tooltipText = "{name} - not available";
          polygonTemplate.fill = am4core.color("#74B266");

          // Create hover state and set alternative fill color
          var hs = polygonTemplate.states.create("hover");
          hs.properties.fill = am4core.color("#367B25");

          var updatedCounties = [];
          var totalPrice = 0;
          this.county_data[stateCode].available.forEach(function (item, index) {
            totalPrice += item.price;
            updatedCounties.push({
              id: item.id,
              name: item.name,
              fill: am4core.color("#4557f7"),
              status: item.name + " - " + item.status,
            });
            console.log(index);
          });

          this.total_value = totalPrice;

          this.county_data[stateCode].sold.forEach(function (item, index) {
            updatedCounties.push({
              id: item.id,
              name: item.name,
              fill: am4core.color("#bfbdbd"),
              status: item.name + " - " + item.status,
            });
            console.log(index);
          });

          // Add some data
          polygonSeries.data = updatedCounties;

          // Bind "fill" property to "fill" key in data
          polygonTemplate.propertyFields.fill = "fill";
          polygonTemplate.propertyFields.tooltipText = "status";
          
          resolve(chart);
        });
      });
    },
    getSubscriptionData() {
      this.busy = true;
      this.$AuthAxios.instance
        .get("/api-access/county-subscriptions/status/", {})
        .then(async (res) => {
          this.county_data = res.data;
          this.states = res.data.abv
          await this.loadStateData(res.data.abv);
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
  },
};
</script>
<style>
.chart-container {
  width: 100%;
  height: 350px;
  /* Or whatever height you want */
}
</style>